import React from "react";
import styled from "styled-components";

const Box = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
`;

const Title = ({ title, secondTitle }) => {
  
  return (
    <Box>
      <h2 className="featurette-heading">
        <span className="muted">{title}{' '}</span> 
       {secondTitle}
      </h2>
    </Box>
  );
};

export default Title;
