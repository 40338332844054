import * as React from "react"
import styled from "styled-components";


import Layout from "../components/layout"
import Seo from "../components/seo"
import Title from '../components/Title';
import Image from '../components/Image';

const Box = styled.div`
  @media(max-width: 1000px){
    padding: 0 20px;
  }
`;

const Container = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  text-align: justify;
  align-items: center;
  flex-wrap: wrap;

  p{
    margin-right:20px;
  }
  h4 {
    margin-left: 10px;
  }
  .image-icon{
    height: 31px;
    width: 31px;
  }
  .image-aseo{
    height: 232px;
    width: 242px;
  }
  .featurette-SubTitulo {
    font-size: 24px;
  }
  @media(max-width: 1000px){
    width: 100%;
  }
`;

const ContainerBox = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  text-align: justify;
  align-items: center;
  flex-wrap: wrap;
  justifyContent: space-between;
  @media(max-width: 1000px){
    flex-direction: column;
    justifyContent: flex-start;
    align-items: flex-start;
  }
`;

const IndexPage = () => (
  <Layout>
    <Seo title="Nuestros Clientes" />
    <Box>
      <Title title='Nuestros' secondTitle='Clientes' />
        <span>Entre otros servicios realizamos el montaje y la mantención de material publicitario desde Rancagua hasta Puerto Montt para la Empresa SUPERMERCADOS UNIMARC , MAXIAHORRO ,MAYORISTA 10,DIPAC, ALVI ,SANTA ISABEL, JUMBO, PHILIP MORRIS CHILE LTDA,CRUZ VERDE SA, PETRIZZIO, VENDOME, OK MARKET construcción y montaje de estructuras de aluminio y vidrios ,para distintas entidades publicas. 
        <br/><br/>Durante este periodo, ESOV se ha caracterizado siempre por su continuo desarrollo, adaptación y responsabilidad a las modernas tecnologías que paralelamente han ido naciendo, situándose siempre a la vanguardia de las empresas y microempresas.
        </span>
    
      <Container style={{ justifyContent: 'center', width: "100%", justifyContent: 'space-between' }}>
     
        <Image classNamee="image-aseo" alt="Clientes Esov" srcName="cli_unimarc.png" width="242" height="232"/>
        <Image classNamee="image-aseo" alt="Clientes Esov" srcName="cli_maxiahorro.png" width="242" height="232"/>
        <Image classNamee="image-aseo" alt="Clientes Esov" srcName="cli_mayorista10.png" width="242" height="232"/>
        <Image classNamee="image-aseo" alt="Clientes Esov" srcName="cli_dipac.png" width="242" height="232"/>
      
        <Image classNamee="image-aseo" alt="Clientes Esov" srcName="cli_alvi.png" width="242" height="232"/>
        <Image classNamee="image-aseo" alt="Clientes Esov" srcName="cli_phillip.png" width="242" height="232"/>
        <Image classNamee="image-aseo" alt="Clientes Esov" srcName="cli_cruzverde.png" width="242" height="232"/>
        <Image classNamee="image-aseo" alt="Clientes Esov" srcName="cli_petrizzio.png" width="242" height="232"/>
      
        <Image classNamee="image-aseo" alt="Clientes Esov" srcName="cli_staisabel.png" width="242" height="232"/>
        <Image classNamee="image-aseo" alt="Clientes Esov" srcName="cli_jumbo.png" width="242" height="232"/>
        <Image classNamee="image-aseo" alt="Clientes Esov" srcName="cli_labvendome.png" width="242" height="232"/>
        <Image classNamee="image-aseo" alt="Clientes Esov" srcName="cli_okmarket.png" width="242" height="232"/>


        
      </Container>
    </Box>
  </Layout>
)

export default IndexPage
    
  
    